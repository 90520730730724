<template>
   <div id="myEchart" ref="myEchart" class="w100 h100" v-loading="loading" element-loading-background="#262c3c"></div>
</template>

<script>

// import * as echarts from 'echarts';
import { provinces, special } from '@/utils/common/provinces'
import {cityMap} from '@/utils/common/js/citymap'
import {getHomepageMap} from '@/api/dataStatement';


export default {
  
  data() {
    return {
        loading:false,
        lon:'',
        lat:'',
        clickFlag:false,//点击加载开关
        paramsFlag:true,//点击防抖开关
        form:{
            city:"",
            province:""
        },
        locations:[],
      mapLabel: 'map',
      mapOption: {
        toolbox: {
            show: false,

            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                dataView: {readOnly: false},
                restore: {},
                saveAsImage: {}
            },
            iconStyle:{
                normal:{
                    color:'#fff'
                }
            }
        },
        // animationDuration:0,
        // animationEasing:'cubicOut',
        // animationDurationUpdate:0
      },
      mapdata: [],
      mapInfo: null,
      selectLable: 'china'
    }
  },
  mounted() {
    // this.initMap();
    this.getHomepageMap();
  },
  methods: {
    //   数据
    async getHomepageMap(){
        try{
        const params = {...this.form};
        this.loading = true;
        const res = await getHomepageMap(params);
        
        this.locations = res.ldata;
        // this.locations.push({
		// 	"latitude":"40.817498",
		// 	"longitude":"111.765617",
		// 	"num":239,
		// 	"placeName":"内蒙古自治区"
		// })
        
        this.initMap();

        }catch(e){
            console.log(e,'e');
        }
        
    },
    async authorGetHomepageMap(){
        // this.clickFlag = true;

        const params = {...this.form};
        this.loading = true;
        const res = await getHomepageMap(params);

        this.locations = res.ldata;
        
    },
    // initMap执行getChina
    getChina() {
        const data = require('@/utils/common/map/china.json')
        let d = [];
        for( var i=0;i<data.features.length;i++ ){
            d.push({
                name:data.features[i].properties.name
            })
        }
        this.mapdata = d;
        //注册地图
        echarts.registerMap('china', data);
        //绘制地图
        this.renderMap('china',d);
    },
    initMap() {
        //地图初始化
        this.mapInfo = echarts.init(document.getElementById('myEchart'));
        console.log(this.mapInfo.resize, 'this.mapInfo.resize----')
        window.onresize = this.mapInfo.resize
        const self = this;
        this.mapInfo.on('click',function (params) {
               self.clickFlag = true;
                if( params.name in provinces ){
                    self.selectLable = 'province'
                    const data = require('@/utils/common/map/province/'+ provinces[params.name] +'.json')
                    echarts.registerMap( params.name, data);
                    var d = [];
                    for( var i=0;i<data.features.length;i++ ){
                        d.push({
                            name:data.features[i].properties.name
                        })
                    }
                    self.renderMap(params.name,d);

                }else if( params.seriesName in provinces ){
                    //如果是【直辖市/特别行政区】只有二级下钻
                    if(  special.indexOf( params.seriesName ) >=0  ){
                        // self.selectLable = 'speCity'
                        self.selectLable = 'china'
                        self.renderMap('china',this.mapdata);

                    
                    }else{                                                                                      
                        //显示县级地图
                        const data = require('@/utils/common/map/city/'+ cityMap[params.name] +'.json')
                        self.selectLable = 'city'
                        echarts.registerMap( params.name, data);
                        var d = [];
                        for( var i=0;i<data.features.length;i++ ){
                            d.push({
                                name:data.features[i].properties.name
                            })
                        }
                        self.renderMap(params.name,d);

                    }	
                }else{
                    self.selectLable = 'china'
                    self.renderMap('china',self.mapdata);

                }
        });
        this.getChina();
    },
    async renderMap(map,data) {
        let zoom = 1
         window.onresize = this.mapInfo.resize
       if(this.selectLable == 'city' && map !== 'china'){//点击请求
          this.form.city = map;
          this.form.province = '';
       }else if(this.selectLable == 'province'){
           zoom = 0.6
          this.form.province = map;
          this.form.city = '';
       }else{//map为china,不传值
          this.form.province = '';
          this.form.city = '';
       }
    // 如果点击走这，第一次不走
       if(this.clickFlag){
            this.clickFlag = false;
            const params = {...this.form};
            this.loading = true;
            const res = await getHomepageMap(params);
            this.locations = res.ldata;
       }
        var dataValue = this.dealWithData();
        if(dataValue.length > 0){//如果有定位，用第一个定位
            // this.lon = dataValue[0].value[0];
            // this.lat = dataValue[0].value[1];
        }else{//如果没有定位，center为null
            this.lon = '';
            this.lat = '';
        }
        const regions = dataValue.map(el => {
            if(this.selectLable === 'china'){
                el.name = el.name.replace(el.name[el.name.length - 1],'');//china,不能有省，市，去掉最后一个字
            }
            return {
                name: el.name,
                itemStyle: {
                    areaColor: '#ff1a1a',
                },
            }
        })
        this.mapOption.geo = {
          map: map,
        //   zoom: 1.23,
          center: this.lon ? [this.lon,this.lat] : null,
          zoom:zoom,
          roam: 'scale',//允许滚动拖拽
          selectedMode: 'single',
          scaleLimit: {//缩放范围
            min: zoom,
            max: 3,
            // max:1
          },
               
        label: {
            emphasis: {
                color: "#fff",
            }
         }, 

          regions,
          itemStyle: {
            normal: {
              areaColor: "#0d0059",
              borderColor: "#389dff",
              borderWidth: 1, //设置外层边框
              shadowBlur: 5,
              shadowOffsetY: 8,
              shadowOffsetX: 0,
              shadowColor: "#01012a",
            },
            emphasis: {
              areaColor: "#185886",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 5,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
      
            }
          },
        }
        this.mapOption.series = [ 
            {
                name: map,
                type: 'map',
                mapType: map,
                roam: false,
                geoIndex: 0,
                nameMap:{
                    'china':'中国'
                },
                label: {
                    normal:{
                        show:true,
                        textStyle:{
                            color:'#999',
                            fontSize:13
                        }  
                    },
                    emphasis: {
                        show: true,
                        textStyle:{
                            color:'#fff',
                            fontSize:13
                        }
                    }
                },
                itemStyle: {
                    normal: {
                        areaColor: '#323c48',
                        borderColor: 'dodgerblue'
                    },
                    emphasis: {
                        areaColor: 'darkorange'
                    }
                },
                data:data,
            },
            {
                symbol: "circle",
                name: "Top 5",
                type: "effectScatter",
                coordinateSystem: "geo",
                data: dataValue,
                geoIndex: 0,
                symbolSize: 8,
                tooltip: {
                    show: false
                },
                encode: {
                value: 2
                },
                showEffectOn: "render",
                rippleEffect: {
                brushType: "stroke",
                color: "#0efacc",
                period: 10,
                scale: 3
                // scale:1
                },
                hoverAnimation: true,
                label: {
                formatter: function(info) {
                    return `${info.name} ${info.value[2]}`
                },
                position:'top',
                backgroundColor: '#fff',
                distance: 10,
                borderColor: '#999',
                padding:[2,3],
                color: '#333',//放射点文字
                show: true
                },
                itemStyle: {
                color: "#0efacc",//放射点颜色
                shadowBlur: 2,
                shadowColor: "#333"
                },
                zlevel: 1
            }	
        ];
        //渲染地图
        this.mapInfo.setOption(this.mapOption);
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    dealWithData() {
        // 以下高亮，表示有站点
      let china = {}
        let province = {}
        let city = {}
        //   处理china定位数据
        let chinaData = {};
        this.locations.forEach(el=>{
            chinaData[el.placeName] = [el.longitude,el.latitude,el.num];
        })
        if(this.selectLable == 'china'){
            china = chinaData;
        }else if(this.selectLable == 'province'){
            province = chinaData;
        }else{
            city = chinaData;
        }
      let geoCoordMap = {};
      if(this.selectLable === 'china') {
        geoCoordMap = china
      } else if (this.selectLable === 'province') {
        geoCoordMap = province
      }else{
        geoCoordMap = city
      }
      var data = [];
      for (var key in geoCoordMap) {
        data.push({ name: key, value: geoCoordMap[key] });
      }
      return data;
    },
  },
  components: {
    
  },
  beforeDestroy() {
    // 组件销毁时主动释放eCharts内存空间
      const chartsInstance = echarts.getInstanceByDom(this.$refs.myEchart);
      chartsInstance && chartsInstance.dispose();
      window.onresize = null;
      this.mapInfo.clear()
  },
}
</script>

<style scoped>

</style>
