<template>
  <div class="box">
      <div id="myCharts" :ref="refLabel"></div>
  </div>

</template>

<script>
// import echarts from 'echarts'

export default {
  props: {
    option: {
      default: () => {}
    },
    refLabel: {
      default: 'myCharts',
    }
  },
  data() {
    return {
      myCharts: null,
    }
  },
  watch: {
    option: {
      handler(val) {
        if(!val) return
        this.$nextTick(() => {
          const myCharts = echarts.init(this.$refs[this.refLabel]);
          myCharts.setOption(val);
          this.myCharts = myCharts
        })
      },
      deep: true,
      immediate: true,
    }
  },
  mounted(){

  },
  methods: {

  },
  components: {

  },
  beforeDestroy() {
    // 组件销毁时主动释放eCharts内存空间
      const chartsInstance = echarts.getInstanceByDom(this.$refs[this.refLabel]);
      chartsInstance && chartsInstance.dispose();
      window.onresize = null;
      this.myCharts.clear()
  },
}
</script>

<style scoped>
.box {
  overflow: hidden;
}
  #myCharts{
    width: 100%;
    height: 500px;
  }
</style>
