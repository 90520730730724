
// export const provinces = {
//   浙江: "zhejiang.js",
//   安徽: "anhui.js",
//   澳门: "aomen.js",
//   北京: "beijing.js",
//   重庆: "chongqing.js",
//   福建: "fujian.js",
//   甘肃: "gansu.js",
//   广东: "guangdong.js",
//   广西: "guangxi.js",
//   贵州: "guizhou.js",
//   海南: "hainan.js",
//   河北: "hebei.js",
//   黑龙江: "heilongjiang.js",
//   河南: "henan.js",
//   湖北: "hubei.js",
//   湖南: "hunan.js",
//   江苏: "jiangsu.js",
//   江西: "jiangxi.js",
//   吉林: "jilin.js",
//   辽宁: "liaoning.js",
//   内蒙古: "neimenggu.js",
//   宁夏: "ningxia.js",
//   青海: "qinghai.js",
//   山东: "shandong.js",
//   上海: "shanghai.js",
//   山西: "shanxi.js",
//   山西1: "shanxi1.js",
//   四川: "sichuan.js",
//   台湾: "taiwan.js",
//   天津: "tianjin.js",
//   香港: "xianggang.js",
//   新疆: "xinjiang.js",
//   西藏: "xizang.js",
//   云南: "yunnan.js"
// };

export const provinces = {
  //23个省
  "台湾": "taiwan",
  "河北": "hebei",
  "山西": "shanxi",
  "辽宁": "liaoning",
  "吉林": "jilin",
  "黑龙江": "heilongjiang",
  "江苏": "jiangsu",
  "浙江": "zhejiang",
  "安徽": "anhui",
  "福建": "fujian",
  "江西": "jiangxi",
  "山东": "shandong",
  "河南": "henan",
  "湖北": "hubei",
  "湖南": "hunan",
  "广东": "guangdong",
  "海南": "hainan",
  "四川": "sichuan",
  "贵州": "guizhou",
  "云南": "yunnan",
  "陕西": "shanxi1",
  "甘肃": "gansu",
  "青海": "qinghai",
  //5个自治区
  "新疆": "xinjiang",
  "广西": "guangxi",
  "内蒙古自治": "neimenggu",
  "宁夏": "ningxia",
  "西藏": "xizang",
  //4个直辖市
  "北京": "beijing",
  "天津": "tianjin",
  "上海": "shanghai",
  "重庆": "chongqing",
  //2个特别行政区
  "香港": "xianggang",
  "澳门": "aomen"
};
export const special = ["北京","天津","上海","重庆","香港","澳门"];