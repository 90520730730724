<template>
  <div class="big-screen">
    <div class="title">
       <div class="title-left">
        <div class="title-left-1">
          <div class="after"> 
            <span>设备总数量(台)</span>
            <span>{{list.deviceNum}} &nbsp;&nbsp;&nbsp;&nbsp;台</span>
          </div>
          <div> 
            <span>今日报警总数量(次)</span>
            <span>{{list.alarmNum}}&nbsp;&nbsp;&nbsp;&nbsp;次</span>
          </div>
          <div> 
            <span>设备在线数量(台)</span>
            <span>{{list.deviceOnlineNum}}&nbsp;&nbsp;&nbsp;&nbsp;台</span>
          </div>
          <div class="before"> 
            <span>设备离线数量(台)</span>
            <span>{{list.deviceOfflineNum}}&nbsp;&nbsp;&nbsp;&nbsp;台</span>
          </div>
        </div>
        <div class="title-left-2 mt20">
          <p class="cff tac ft14">设备激活占比</p>
          <lttEcharts :ref='activateLabel' :option='activateOption'></lttEcharts>
        </div>
      </div>
      <div class="title-right">
        <map-echarts></map-echarts>
      </div>
    </div>
    <div class="bottom df">
      <div class="fd df chartsBox">
        <div class="cBox mb10">
          <div class="outCircle"></div>
          <div class="innerCircle"></div>
          <div class="title">
            <span>每日报警次数</span>
          </div>
        </div>
        <div class="bottom-2 border1 ">
          <!-- 告警 -->
          <lttEcharts :ref='reportLabel' :option='reportOption'></lttEcharts>
        </div>
      </div>
     
      <!-- 行驶中状态 -->
      <div class="fd df chartsBox">
        <div class="cBox mb10">
          <div class="outCircle"></div>
          <div class="innerCircle"></div>
          <div class="title">
            <span>设备状态统计</span>
          </div>
        </div>
        <div class="bottom-2 border2">
          <lttEcharts :ref='facilityLabel' :option='facilityOption'></lttEcharts>
        </div>
      </div>

      <!-- 5,10,30天 -->
      <div class="fd df chartsBox">
        <div class="cBox mb10">
          <div class="outCircle"></div>
          <div class="innerCircle"></div>
          <div class="title">
            <span>设备离线数量</span>
          </div>
        </div>
        <div class="bottom-2 bottom-3">
          <lttEcharts class="deviceOffline" :ref='offLineLabel' :option='offLineOption'></lttEcharts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import lttEcharts from '@/components/lttEcharts'
import MapEcharts from './map'
// import echarts from 'echarts'
import {getfunderHomepage} from "@/api/dataStatement";
export default {
  data() {
    return {
      list:[],
      pathSymbols :'image://https://qianshoushoutest.oss-cn-hangzhou.aliyuncs.com/resources/images/yellow2.png',
      /** 行驶中状态*/
      facilityLabel: 'facility',
      facilityOption: {},
      /* 告警*/
      reportLabel: 'report',
      reportOption: {},
      /** 离线 */
      offLineLabel: 'offLine',
      offLineOption: {},
      /** 激活 */
      activateLabel: 'activate',
      activateOption: {},
      /** 地图 */
    }
  },
  mounted() {

    this.initFacility()
    this.initReport()
    this.initOffLine()
    this.initActivate()//设备激活占比
    this.getfunderHomepage();
  },
  methods: {
    async getfunderHomepage(){
      try{
        const res = await getfunderHomepage();
        this.list = res.mdata.funderHomepage;
        //设备占比
        this.activateOption.series[0].data[1].value = this.list.inactivatedNum;//第二个未激活
        this.activateOption.series[0].data[0].value =  this.list.activationNum;//第一个激活
        // 5-10-30天
        let offlineArr = [];
        let pathSymbols = this.pathSymbols;
        offlineArr.push(this.list.OfflineNumMoreThan30days,this.list.offlineNumWithin30days,this.list.offlineNumWithin10days,this.list.offlineNumWithin5days);
        const newOfflineArr = offlineArr.map((el) => {
           return {value:el, symbol:pathSymbols} 
        });
        this.offLineOption.series[0].data = newOfflineArr;
        // 设备状态
        let deviceArr = [];
        deviceArr.push(this.list.deviceDrivingNum,this.list.deviceStillNum,this.list.deviceOfflineNum);
        this.facilityOption.series[0].data = deviceArr;
        // 告警
        let dailyAlarmDate = [];//x轴日期
        let dailyAlarmNum = [];//报警次数数据
        this.list.dailyAlarm.forEach(el=>{
          dailyAlarmDate.push(el.date);
          dailyAlarmNum.push(el.num);
        })
        this.reportOption.series[0].data = dailyAlarmNum;
        this.reportOption.xAxis.data = dailyAlarmDate;
      
      
        
      }catch(e){
        console.log(e,'e');
      }
    },
    /** 设备状态 */
    initFacility() {
      this.facilityOption = {
        title: {
          show: false,
          text: '设备状态统计',
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
              show: false
          },
          axisLabel: {
            show: true,
            color: '#fff',
            inside: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#00eaff',
              width: 2,
            }
          },
          data: ['行驶中', '静止', '离线']
        },
        yAxis: {
            type: 'value',
            show: false,
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                  color: '#00eaff',
                  width: 2,
                }
            },
        },
        series: [{
            data: [120, 200, 150],
            type: 'bar',
            barWidth: '20%',
            itemStyle: {
              normal: {
                color: function(params) {
                  const arr = ['#00eaff', '#ffde00', '#fff']
                  return arr[params.dataIndex]
                },
                label:{
                  show: true,
                  color: '#fff',
                  position: 'top'
                },
              }
            }
            
        }]
      }
    },
    /** 告警 */
    initReport() {
      this.reportOption = {
        title: {
          show: false,
          text: '每日报警次数',
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          show: true,
          axisTick: {
              show: false
          },
          axisLabel: {
            show: true,
            color: '#fff',
            inside: false,

          },
          splitLine: {
           
            show: false,
            lineStyle: {
                color:  ['#15586b'],
              }
          },
          axisLine: {
            show: false,
             lineStyle: {
                color: '#00eaff',
                width: 2,
              }
          },
        },
        yAxis: {
            type: 'value',
            show: true,
            splitLine: {
              show: false,
              lineStyle: {
                color:  ['#15586b'],
              }
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisLine: {
              show: false,
               lineStyle: {
                 color: '#15586b',
               }
          },
        },
        series: [{
            data: [],
            type: 'line',
            lineStyle:{
              color:'#ff3600' //折线颜色
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#613b36'
              }, {
                  offset: 1,
                  color: '#313d48'
              }])
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  color: '#fff'
                }
              }
            }
            
        }]
      }
    },
    /**离线 */
    initOffLine() {
      this.offLineOption = {
        title: {
          show: false,
          text: '设备离线数量',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          containLabel: true,
          left: 10
        },
        xAxis: {
          type: 'value',
          show: false,
        },
        yAxis: {
            type: 'category',
            data: ['30天以上', '30天以内', '10天以内', '5天以内'],
            axisTick: {
                show: false
            },
            axisLabel: {
              interval: 0,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#fff'
              }
            },
        },
        series: [{
            symbolSize: ['40%', '60%'],
            data: [],
            symbol: 'circle',
            symbolRepeat: true,
            // data: [10, 20, 30, 40],
            type: 'pictorialBar',
            itemStyle: {
              normal: {
                // color: function(params) {
                //   const arr = ['#ffde00', '#fffefa']
                //   return arr[params.dataIndex]
                // },
                label: {
                  show: true,
                  position: 'right',
                  color: '#0ca6b8'
                }
              },
              
            }
            
        }]
      }

    },
    /** 激活 */
    initActivate() {
      this.activateOption = {
        color: ['#00eaff', '#090d20'],
        legend: {
            bottom: '1%',
            left: 'center',
            textStyle: {
              color: '#fff'
            }
        },
        series: [
            {
                type: 'pie',
                radius: ['50%', '70%'],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    position: 'center',
                    color: '#00eaff',
                    formatter:function(val) {
                      if(val.data.name == "未激活") {
                        return '';
                      }
                      return `${val.percent}%`  
                    }
                },
                labelLine: {
                    show: true
                },
                itemStyle: {
                  // borderColor: '#00eaff',
                  // borderWidth: '1', 
                },

                data: [
                    {value: 1048, name: '已激活'},
                    {value: 735, name: '未激活'},
                ]
            }
        ]
      }
    },
  },
  components: {
    lttEcharts,
    MapEcharts,
  }
}
</script>

<style scoped lang='less'>

.deviceOffline{
  margin:0px;
}
.chartsBox{
   .cBox{
     position:relative;
     width:80px;
     height:80px;
     border-radius:50%;
     box-shadow:0 0 3px rgb(8, 239, 255);
      z-index:10;
     .outCircle,
     .innerCircle{
        position:absolute;
        transform:translate(-50%,-50%);
        top:50%;
        left:50%;
        border-radius:50%;
        box-shadow:0 0 5px rgb(8, 239, 255);
        box-shadow:inset 0 0 5px rgb(8, 239, 255);
        border:1px solid rgb(8, 239, 255);
     }
     .outCircle{
        width:70px;
        height:70px;
     }
     .innerCircle{
        width:50px;
        height:50px;
        border:3px solid rgb(8, 239, 255);

     }
     .title{
        position:absolute;
        top:22px;
        left:65px;
        height:35px;
        line-height:35px;
        width:140px;
        color:#fff;
        font-weight:bold;
        border-radius:8px;
        box-shadow:0 0 5px rgb(8, 239, 255);
        border:1px solid rgb(8, 239, 255);
        border-left:0px;
        border-left-color:transparent;
        span{
          margin-left:25px;
        }
       
     }
   }
}
.chartsBox{
  width:30%;
  .bottom-2{
    width:100%;
  }
}
.big-screen {
  background: #262c3c;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  .title {
    display: flex;
    flex: 3;
  }
  .title-left {
    width: 20%;
    color: #00eaff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &-1 {
      background: #173c4f;
      display: flex;
      align-items: center;
      flex-direction: column;
      border: 1px solid #00c6ff;
      border-left: 3px solid #00eaff;
      border-right: 3px solid #00eaff;
      justify-content: space-around;
      width: 100%;
      
      div {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
       
      }
      .after::after {
        content: '';
        position: absolute;
        width: 20px;
        background: #00eaff;
        height: 2px;
        left: -2px;
        top: -12px;
      }
      .after::before {
        content: '';
        position: absolute;
        width: 20px;
        background: #00eaff;
        height: 2px;
        right: -2px;
        top: -12px;
      }
      .before::after {
        content: '';
        position: absolute;
        width: 20px;
        background: #00eaff;
        height: 2px;
        left: -2px;
        bottom: -12px;
      }
      .before::before {
        content: '';
        position: absolute;
        width: 20px;
        background: #00eaff;
        height: 2px;
        right: -2px;
        bottom: -12px;
      }
    }
    &-2 {
      width: 100%;
    }
  }
  .title-right {
    width: 80%;
  }
  .bottom {
    margin-top: 10px;
    display: flex;
    width: 100%;
    flex:1;
    justify-content: space-between;
    &-2 {
      background: #1a4258;
      // width: 30%;
    }
    &-3 {
      background: inherit;
    }
  }
  .border2 {
    border-bottom: 2px solid #00eaff;
    border-left: 2px solid #00eaff;
  }
  .border1 {
     border-bottom: 2px solid #00eaff;
  }

  &/deep/ #myCharts {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

</style>
